<template>
  <OnClickOutside @trigger="() => togglePanel(false)">
    <div
      :class="hasBorder ? 'border border-cs-black dark:border-cs-medium-grey rounded-full px-4 py-2' : ''"
      class="relative cursor-pointer"
    >
      <div>
        <div class="flex" @click="() => togglePanel(null)">
          <component
            :is="icon"
            :class="
              iconHasBorder
                ? '[&>path]:stroke-cs-black [&>g>path]:stroke-cs-black dark:[&>path]:stroke-white dark:[&>g>path]:stroke-white'
                : ''
            "
          />
          <span v-if="text" class="ml-1 text-cs-black dark:text-white text-sm">{{ text }}</span>
        </div>
        <div
          v-if="showPanel"
          :class="getPopupPosition()"
          class="bg-white dark:bg-black absolute top-10 z-50 rounded-xl border dark:border-cs-medium-grey py-2 flex flex-col min-w-max"
          @click="() => togglePanel(false)"
        >
          <slot />
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script lang="ts" setup>
import {OnClickOutside} from '@vueuse/components'

interface ComponentProps {
  icon: any
  text?: string | null
  hasBorder?: boolean
  iconHasBorder?: boolean
  customTopPosition?: string | null
  customRightPosition?: string | null
}

const props = withDefaults(defineProps<ComponentProps>(), {
  text: null,
  hasBorder: false,
  iconHasBorder: false,
  customTopPosition: null,
  customRightPosition: null
})

const showPanel = ref<boolean>(false)

const togglePanel = (value: boolean | null) => {
  if (value === null) {
    showPanel.value = !showPanel.value
  } else {
    showPanel.value = value
  }
}

const getPopupPosition = () => {
  let className = ''
  const {customTopPosition, customRightPosition} = props
  if (customTopPosition) {
    className += ` ${customTopPosition} `
  } else {
    className += ' top-10 '
  }
  if (customRightPosition) {
    className += ` ${customRightPosition} `
  } else {
    className += ' right-0 '
  }
  return className
}
</script>
